<template>
    <v-row justify="center" class="py-5">
        <v-col style="text-align: center" cols="12" sm="6">
            <v-form onSubmit="return false;" @keyup.enter.native="search">
            <p>
                Search for a brand profile:
            </p>
            <v-text-field v-model="searchQuery" ref="searchInput" dense solo color="teal">
                <template #prepend-inner>
                    <font-awesome-icon :icon="['fas', 'search']" fixed-width/> <!-- style="font-size: 20px;" -->
                </template>
            </v-text-field>
            <v-btn @click="search" elevation="4" color="teal white--text">Search</v-btn>
            </v-form>
            <p class="mb-15"></p>
            <v-alert v-if="notFound">
                No brands matched your query.
            </v-alert>
            <template v-if="resultList.length > 0">
            <BrandList :list="resultList" @selected="onSelectBrand"/>
            </template>
            <p class="mt-12"><a :href="mainWebsiteURL">Create a brand profile</a></p>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
import BrandList from '@/components/BrandList.vue';

export default {
    components: {
        BrandList,
    },
    data: () => ({
        searchQuery: '',
        searchTimestamp: null,
        notFound: false,
        notFoundTimeout: null,
        resultList: [],
    }),
    computed: {
        mainWebsiteURL() {
            return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://brandprofile.org';
        },
    },
    methods: {
        reset() {
            this.searchQuery = '';
            this.notFound = false;
            if (this.notFoundTimeout) {
                clearTimeout(this.notFoundTimeout);
            }
            this.resultList = [];
        },
        async search() {
            if (Number.isInteger(this.searchTimestamp) && this.searchTimestamp + 500 > Date.now()) {
                return;
            }
            this.searchTimestamp = Date.now();
            try {
                if (this.$route.query.q !== this.searchQuery) {
                    // update URL in address bar
                    this.$router.replace({ name: 'search', query: { q: this.searchQuery } });
                }
                this.notFound = false;
                if (this.notFoundTimeout) {
                    clearTimeout(this.notFoundTimeout);
                    this.notFoundTimeout = null;
                }
                this.$store.commit('loading', { search: true });
                const response = await this.$client.main().brand.search({ q: this.searchQuery });
                console.log(`Search.vue: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.resultList = response.list;
                    if (response.list.length === 0) {
                        this.notFound = true;
                        this.notFoundTimeout = setTimeout(() => { this.notFound = false; }, 15000); // clear message in 15 seconds
                    }
                }
            } catch (err) {
                console.error('failed to search', err);
                // this.error = true;
            } finally {
                this.$store.commit('loading', { search: false });
            }
        },
        onSelectBrand({ alias }) {
            if (this.$route.query.intent === 'unsubscribe') {
                this.$router.push({ name: 'brand-view-profile', params: { alias }, hash: '#unsubscribe' });
            } else {
                this.$router.push({ name: 'brand-view-profile', params: { alias } });
            }
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
    },
    created() {
        // listen for search events coming from any component (but specifically the navbar search button)
        this.$bus.$on('search', (q) => {
            this.searchQuery = q;
            this.$nextTick(() => {
                setTimeout(() => { this.activate('searchInput'); }, 1);
            });
            if (q) {
                this.search();
            } else {
                this.reset();
            }
        });
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => { this.activate('searchInput'); }, 1);
        });
        this.searchQuery = this.$route.query.q ?? '';
        if (this.searchQuery) {
            this.search();
        }
    },
};
</script>
