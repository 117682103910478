<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="green--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- TODO: the menu can have sort order features for results -->
            <!-- <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="green" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-site-settings' }">
                        <v-list-item-title>Settings</v-list-item-title>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu> -->
        </v-toolbar>
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No brands yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item)">
                <v-list-item-content class="text-start">
                    <BrandListItem :attr="item"></BrandListItem>
                </v-list-item-content>
                <!-- <v-list-item-action>
                <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="green" v-on="on">
                            <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                        </v-btn>
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item-group>
                        <v-list-item :to="{ name: 'account-delete-brand', params: { accountId, brandId: item.id } }">
                            <v-list-item-content>
                                <v-list-item-title style="color: red;">Delete...</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                </v-list-item-action> -->
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
import BrandListItem from '@/components/list-item/BrandListItem.vue';

export default {
    components: {
        BrandListItem,
    },

    props: ['list'],

    data: () => ({
    }),

    computed: {
        label() {
            if (this.selectOne) {
                return 'Select a brand';
            }
            switch (this.list.length) {
            case 0:
                return 'No brands';
            case 1:
                return '1 brand';
            default:
                return `${this.list.length} brands`;
            }
        },
    },

    methods: {
        onClickItem({ id, alias }) {
            this.$emit('selected', { id, alias });
        },
    },
};
</script>
